import { Outlet } from 'react-router-dom'
import { Container } from '@components/container'

import { Footer, Header, Hero } from './components'

export const LandingLayout = () => {
  return (
    <div
      style={{ minHeight: '100vh' }}
      className="flex flex-col justify-between"
    >
      <div className="relative mb-20">
        <div className="w-full h-full absolute top-0 left-0 bg-gradient-to-b from-black/40 to-transparent" />

        <Container className="pt-5 max-w-[1200px]">
          <div className="relative z-30">
            <Header />
          </div>

          <Hero />
        </Container>
      </div>

      <Container>
        <Outlet />
      </Container>

      <div className="bg-neutral-800 mt-10">
        <Container>
          <Footer />
        </Container>
      </div>
    </div>
  )
}
