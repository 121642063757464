import {
  useEffect, useRef, useState,
} from 'react'
import { User } from '@api/gql/graphql'
import { restEndpoint, RestEndpointResponse } from '@api/rest-endpoint'
import logo from '@assets/logo.png'
import { toast } from '@components/toast'
import { TopMenuItem } from '@constants/analytic'
import {
  faArrowRightFromBracket,
  faBox,
  faPlus,
  faUser,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { amplitude, AmplitudeEvent } from '@helpers/amplitude'
import { tokenStore } from '@helpers/token-store'
import { ProfileButton } from '@layouts/components/profile-button'
import { ModalType } from '@modals/_types'
import { useModal } from '@modals/core/use-modal'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from '@nextui-org/dropdown'
import { Link } from '@nextui-org/link'
import { Spinner } from '@nextui-org/spinner'

export const Header = () => {
  const { openModal } = useModal()

  const [visibleMenu, setVisibleMenu] = useState<boolean>(false)
  const [profile, setProfile] = useState<User | null>(null)
  const fetched = useRef<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (fetched.current || !tokenStore.get()) {
      return
    }

    setLoading(true)

    fetched.current = true

    restEndpoint
      .get<RestEndpointResponse<User>, any>('/profile')
      .then((result) => {
        setProfile(result.content)
      })
      .catch((e) => {
        if (e.httpStatus !== 401) {
          toast.error(e.message)
        }
      }).finally(() => {
        setLoading(false)
      })
  }, [])

  const logout = () => {
    restEndpoint
      .post('/logout')
      .then(() => {
        setProfile(null)
        tokenStore.remove()

        amplitude.event({ type: AmplitudeEvent.LogoutClick })
        window.location.href = '/'
      })
      .catch((e) => {
        toast.error(e.message)
      })
  }

  const onLogoutWithModal = async () => {
    openModal({
      type: ModalType.CONFIRM_MODAL,
      title: 'Log out of your account?',
      message: '',
      onCancel: () => {},
      onConfirm: logout,
    })
  }

  const onOpenMenu = () => {
    if (!profile) {
      openModal({
        type: ModalType.LOGIN_MODAL,
      })
    } else {
      setVisibleMenu(true)
    }
  }

  return (
    <div className="grid grid-cols-3 mb-8 px-4 lg:px-0">
      <div />

      <Link className="flex justify-center" href="/">
        <img src={logo} alt="Logo" className="object-contain max-w-40 lg:max-w-60" />
      </Link>

      <div className="flex justify-end">
        <Dropdown size="lg" isOpen={visibleMenu && !!profile} onOpenChange={setVisibleMenu}>
          <DropdownTrigger>
            <button
              type="button"
              className="flex outline-none gap-3 items-center hover:opacity-80 cursor-pointer transition-opacity"
              onClick={onOpenMenu}
            >
              {loading
                ? <Spinner color="white" size="sm" />
                : (
                  <ProfileButton profile={profile} />
                )}
            </button>
          </DropdownTrigger>

          <DropdownMenu
            variant="faded"
            aria-label="Dropdown menu with description"
          >
            <DropdownItem
              key="new-album"
              as={Link}
              className="text-black"
              href="/create"
              onClick={() => {
                amplitude.event({
                  type: AmplitudeEvent.TopMenuSelectMenuItem,
                  name: TopMenuItem.NewAlbum,
                })
              }}
              startContent={<FontAwesomeIcon icon={faPlus} />}
            >
              New album
            </DropdownItem>

            <DropdownItem
              key="orders"
              as={Link}
              className="text-black"
              href="/orders"
              onClick={() => {
                amplitude.event({
                  type: AmplitudeEvent.TopMenuSelectMenuItem,
                  name: TopMenuItem.Orders,
                })
              }}
              startContent={<FontAwesomeIcon icon={faBox} />}
            >
              Orders
            </DropdownItem>

            <DropdownItem
              key="profile"
              as={Link}
              className="text-black"
              href="/profile"
              onClick={() => {
                amplitude.event({
                  type: AmplitudeEvent.TopMenuSelectMenuItem,
                  name: TopMenuItem.Profile,
                })
              }}
              startContent={<FontAwesomeIcon icon={faUser} />}
            >
              Profile
            </DropdownItem>

            <DropdownItem
              key="logout"
              as="button"
              className="text-black text-left"
              startContent={<FontAwesomeIcon icon={faArrowRightFromBracket} />}
              onClick={(e) => {
                e.preventDefault()
                onLogoutWithModal()
              }}
            >
              Logout
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
  )
}
