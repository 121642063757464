import cookie from 'js-cookie'

const getDefaultOptions = (): Cookies.CookieAttributes => ({
  path: '/',
  expires: 59,
  secure: !import.meta.env.DEV,
  domain: window.location.hostname,
})

/**
 * У Facebook срок жизни токена 60 суток.
 * Здесь cookie истечет на день раньше чтобы пользователь не увидел ошибку доступа к api
 * Токен тоже наследует срок жизни от facebook access token
 */
export const tokenStore = {
  set: (token: string) => {
    cookie.set('token', token, getDefaultOptions())
  },
  get: () => cookie.get('token'),
  remove: () => cookie.remove('token', getDefaultOptions()),
}
