import { CoverType, OAuthType, User } from '@api/gql/graphql'
import { ModalName, PageName, TopMenuItem } from '@constants/analytic'
import amp from 'amplitude-js'

export enum AmplitudeEvent {
  PageView = 'page.view',
  ModalView = 'modal.view',

  MainClickCreate = 'main.click.create',
  CreateAlbumCreate = 'create.album.create',
  CreateDone = 'create.done',
  LoginClick = 'login.click',
  LoginCompleted = 'login.completed',

  AlbumClickPrevPage = 'album.click.prev_page',
  AlbumClickNextPage = 'album.click.next_page',
  AlbumSelectCoverLayout = 'album.select.cover_layout',
  AlbumDeleteImage = 'album.delete.image',
  AlbumUpdate = 'album.update',
  AlbumSelectQuantity = 'album.select.quantity',
  AlbumClickOrder = 'album.click.order',

  TopMenuSelectMenuItem = 'top_menu.select.menu_item',
  ProfileConfirmAccountDelete = 'profile.confirm.account_delete',

  LogoutClick = 'logout.click',
}

type AmplitudeParams =
    ({ type: AmplitudeEvent.PageView } & { name: PageName }) |
    ({ type: AmplitudeEvent.PageView } & { name: PageName.Album, album_id?: string }) |
    ({ type: AmplitudeEvent.PageView } & { name: PageName.Order, order_id?: string }) |
    ({ type: AmplitudeEvent.ModalView } & { name: ModalName }) |
    ({ type: AmplitudeEvent.MainClickCreate }) |
    ({ type: AmplitudeEvent.CreateAlbumCreate } & { from_date: string, to_date: string }) |
    ({ type: AmplitudeEvent.CreateDone } & { time_seconds: number }) |
    ({ type: AmplitudeEvent.LoginClick } & { oauth_type: OAuthType }) |
    ({ type: AmplitudeEvent.LoginCompleted } & { oauth_type: OAuthType, user_id: string }) |
    ({ type: AmplitudeEvent.AlbumClickPrevPage } & { page: number }) |
    ({ type: AmplitudeEvent.AlbumClickNextPage } & { page: number }) |
    ({ type: AmplitudeEvent.AlbumSelectCoverLayout } & { cover_type: CoverType }) |
    ({ type: AmplitudeEvent.AlbumDeleteImage } & { image_id: string }) |
    ({ type: AmplitudeEvent.AlbumUpdate } & { title: string, creator_name: string }) |
    ({ type: AmplitudeEvent.AlbumSelectQuantity } & { quantity: number }) |
    ({ type: AmplitudeEvent.AlbumClickOrder } & {
      order_id: string,
      pages_count: number,
    }) |
    ({ type: AmplitudeEvent.TopMenuSelectMenuItem } & { name: TopMenuItem }) |
    ({ type: AmplitudeEvent.LogoutClick }) |
    ({ type: AmplitudeEvent.ProfileConfirmAccountDelete })

const init = () => {
  amp.getInstance().init(import.meta.env.VITE_AMPLITUDE_API_KEY)
}

const setUser = (user: User) => {
  amp.getInstance().setUserId(user.id)
  amp.getInstance().setUserProperties({
    email: user.email,
    fb_id: user.fb_id,
    name: user.name,
  })
}

const event = (params: AmplitudeParams) => {
  amp.getInstance().logEvent(params.type, {
    ...params,
    type: undefined,
  })
}

export const amplitude = {
  init,
  event,
  setUser,
}
