export const PRIVACY_LINK = 'https://www.iubenda.com/privacy-policy/25863337'
export const COOKIE_POLICY = 'https://www.iubenda.com/privacy-policy/25863337/cookie-policy'

export const LOGIN_URL = `${import.meta.env.VITE_REST_ENDPOINT}/oauth/facebook/login`

export const PEECHO_ORDER_LINK = import.meta.env.VITE_ENV === 'production'
  ? 'https://www.peecho.com/mio-dashboard/orders/'
  : 'https://test.www.peecho.com/mio-dashboard/orders/'

export const ASSET_URL = `${import.meta.env.VITE_REST_ENDPOINT}/asset`
